@import "styles/variables.scss";

div.table {
  border-radius: 12px;
  margin-bottom: 2rem;
  .search {
    margin-bottom: 2.37rem;
  }
  tr {
    th {
      height: 1.5rem;
    }
    td {
      height: 1.5rem;
    }
  }

  div.table-container {
    padding: 2.6rem 1.25rem;
    border: 1px $background-color-3 solid;
    border-radius: 12px;
    min-height: 42rem;
    .table-head {
      margin-bottom: 2rem;
    }
  }

  div.pagination {
    justify-content: center;
    margin-top: 2.6rem;
  }

}

div.popper .options {
  background: $background-color-1;
  border-radius: 8px;
  padding: 1rem;
  border: 1px $background-color-3 solid;

  :hover {
    button p {
      color: $primary-color-2;
    }
    button path {
      fill: $primary-color-2;
    }
  }
 
}

div.no-results {
  div.no-results-text {
    padding-left: 1rem;
    &-title {

      margin-bottom: 1rem;
    }
  }
}
